let appTableView = () =>
    import(
        /* webpackChunkName: "schemaTableView" */ "@/features/schemas/views/SchemaTableView.vue");

export default [
    {
        path: "/action-logs",
        name: "actionLogs",
        staticProps: { entityKey: "actionLog" },
    },
    {
        path: "/clients",
        name: "clients",
        staticProps: { entityKey: "client" },
    },
    {
        path: "/client-rates",
        name: "clientRates",
        staticProps: { entityKey: "clientRate" },
    },
    {
        path: "/cliques",
        name: "cliques",
        staticProps: { entityKey: "clique" },
    },
    {
        path: "/files",
        name: "files",
        staticProps: { entityKey: "file" },
    },
    {
        path: "/people",
        name: "people",
        staticProps: { entityKey: "person" },
    },
    {
        path: "/positions",
        name: "positions",
        staticProps: { entityKey: "position" },
    },
    {
        path: "/projects",
        name: "projects",
        staticProps: { entityKey: "project" },
    },
    {
        path: "/project-progress-invoices",
        name: "projectProgressInvoices",
        staticProps: { entityKey: "projectProgressInvoice" },
    },
    {
        path: "/roles",
        name: "roles",
        staticProps: { entityKey: "role" },
    },
    {
        path: "/role-claims",
        name: "roleClaims",
        staticProps: { entityKey: "roleClaim" },
    },
    {
        path: "/settings",
        name: "settings",
        staticProps: { entityKey: "setting" },
    },
    {
        path: "/task-activities",
        name: "taskActivities",
        staticProps: { entityKey: "taskActivity" },
    },
    {
        path: "/task-comments",
        name: "taskComments",
        staticProps: { entityKey: "taskComment" },
    },
    {
        path: "/task-files",
        name: "taskFiles",
        staticProps: { entityKey: "taskFile" },
    },
    {
        path: "/task-items",
        name: "taskItems",
        staticProps: { entityKey: "taskItem" },
    },
    {
        path: "/task-people",
        name: "taskPeople",
        staticProps: { entityKey: "taskPerson" },
    },
    {
        path: "/times",
        name: "times",
        staticProps: { entityKey: "time" },
    },
    {
        path: "/users",
        name: "users",
        staticProps: { entityKey: "user" },
    },
    {
        path: "/user-roles",
        name: "userRoles",
        staticProps: { entityKey: "userRole" },
    },
    {
        path: "/user-settings",
        name: "userSettings",
        staticProps: { entityKey: "userSetting" },
    },
].map(e => {
    return { ...e, component: appTableView };
});
