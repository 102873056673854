import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            darkMode: s => s.authentication.user?.darkMode,
            filterTasksByOneProject: s => s.authentication.user.filterTasksByOneProject,
            showTaskSummary: s => s.authentication.user.showTaskSummary,
        }),
    }
};
