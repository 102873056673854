<template>
    <v-btn
        icon
        :dark="darkMode"
        :small="small"
        v-on="$listeners">
        <v-icon :small="small">
            {{ icon }}
        </v-icon>
    </v-btn>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            default: null,
        },
        small: {
            type: Boolean,
            default: false,
        },
    }
};
</script>
