import axios from "axios";

export async function get(key, id = null) {
    const url = id == null ?
        `/api/${key}` :
        `/api/${key}/${id}`;

    const response = await axios.get(url);
    return response.data;
}

export async function list(key, model) {
    const response = await axios.post(`/api/${key}/list`, model ?? null);
    return response.data.items;
}

export async function getMany(key, ids) {
    const response = await axios.post(`/api/${key}/getMany`, ids);
    return response.data.items;
}

export async function add(key, model, followLocation = false) {
    const response = await axios.post(`/api/${key}`, model);
    if(followLocation) {
        return await getViaLocationHeader(response);
    }
    return response.data;
}

export async function update(key, model, followLocation = false) {
    const response = await axios.put(`/api/${key}`, model);
    if(followLocation) {
        return await getViaLocationHeader(response);
    }
    return response.data;
}

export async function save(key, model, followLocation = false) {
    const method = model.id ? update : add;
    return await method(key, model, followLocation);
}

export async function delete_(key, id) {
    await axios.delete(`/api/${key}/${id}`);
}

export async function getViaLocationHeader(response) {
    const uri = response.headers.location;
    const getResponse = await axios.get(uri);
    return getResponse.data;
}

export default { get, getMany, list, add, update, save, delete: delete_ };
