import api from "@/services/apiService";

function getDefaultState() {
    return { 
        time: null,
        isLoaded: false
    }
}

const state = getDefaultState;

const getters = {
    currentTaskItemId(state) {
        return state.time?.taskItemId;
    }
}

const actions = {
    async load({commit}) {
        const time = await api.get("myTimes/current");
        commit("time", time);
        commit("isLoaded", true);
    },
    async start({ commit }, taskItemId) {
        const time = await api.add("myTimes/start", { taskItemId }, true)
        commit("time", time);
    },
    async stop({ commit }) {
        await api.update("myTimes/stop")
        commit("clear");
    },
    clear({ commit }) {
        commit("clear");
    }
}

const mutations = {
    time(state, value) {
        state.time = value;
    },
    clear(state) {
        state.time = null;
    },
    isLoaded(state) {
        state.isLoaded = true;
    }
};

export const time = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}