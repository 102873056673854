import Vue from "vue";
import Router from "vue-router";
import accountRoutes from "./accountRoutes";
import tableRoutes from "./tableRoutes";
import itemRoutes from "./itemRoutes";
import reportRoutes from "./reportRoutes";
import { parseQuery, stringifyQuery } from "@/services/queryStringService"

Vue.use(Router);

let routes = [
    {
        path: "/",
        name: "home",
        component: () => import("@/features/dashboard/Index.vue"),
    },
    {
        path: "/tasks",
        name: "tasks",
        component: () => import("@/features/tasks/views/Index.vue"),
        children: [
            {
                path: ":taskId",
                component: () => import("@/features/tasks/views/TaskFormRouteDialog.vue")
            }
        ]
    },
    {
        path: "/time",
        name: "time",
        component: () => import("@/features/time/views/Index.vue"),
    },
    {
        path: "/admin",
        name: "admin",
        component: () => import("@/features/admin/Index.vue"),
    },
    {
        path: "/reports",
        name: "reports",
        component: () =>
            import("@/features/reports/views/Reports.vue"),
    },
    {
        path: "/task-import",
        name: "taskImport",
        component: () =>
            import("@/features/import/TaskImport.vue"),
    },
    {
        path: "/management",
        name: "management",
        component: () => import("@/features/management/Index.vue"),
    },
    {
        path: "/staff-times/:personId",
        name: "staffTimes",
        component: () => import("@/features/staff/views/Index.vue"),
    },
    {
        path: "*",
        redirect: "/",
    },
];

routes = routes
    .concat(accountRoutes)
    .concat(tableRoutes)
    .concat(itemRoutes)
    .concat(reportRoutes);

// Pass the query string and route parameters as props into all routes.
routes.forEach(addProps);

function addProps(r) {
    r.props = route => ({
        ...route.params,
        ...route.query,
        ...r.staticProps
    });

    r.children?.forEach(addProps);
}

const router = new Router({
    mode: "history",
    parseQuery,
    stringifyQuery,
    routes: routes
});

export default router;
