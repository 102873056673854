import { render, staticRenderFns } from "./AppFileUpload.vue?vue&type=template&id=37b6b9ce&scoped=true&"
import script from "./AppFileUpload.vue?vue&type=script&lang=js&"
export * from "./AppFileUpload.vue?vue&type=script&lang=js&"
import style0 from "./AppFileUpload.vue?vue&type=style&index=0&id=37b6b9ce&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37b6b9ce",
  null
  
)

export default component.exports