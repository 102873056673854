<template>
    <schema-table
        :key="item.entity.key"
        class="flex-grow-1 mt-3"
        :entity-key="item.entity.key"
        :fixed-values="getFixedValues(item)" />
</template>

<script>
import { get } from "@/features/schemas/services/schemaApi";

export default {
    types: [
        "default"
    ],
    props: {
        itemId: {
            type: [Number, String],
            default: null
        },
        item: {
            type: Object,
            default: null
        },
        entityKey: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            parentItem: null
        }
    },
    watch: {
        itemId: {
            immediate: true,
            handler(newValue) {
                this.loadItem(newValue);
            }
        }
    },
    methods: {
        async loadItem(itemId) {
            if (!itemId) {
                return;
            }
            this.parentItem = await get(this.entityKey, itemId);
        },
        getFixedValues(item) {
            let result = {};
            result[item.field.key] = this.itemId;

            return result;
        }
    }
}
</script>
