import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const theme = {
    primary: "#012A94",
    secondary: "#FF5F01",
    tertiary: "#8099AD",
    quaternary: "#FF5F01",
    quinary: "#FF5F01",
    accent: "#0DBF66",
    error: "#C52E00",
    warning: "#FB8C00"
};

export default new Vuetify({
    icons: {
        iconfont: "mdi",
    },
    theme: {
        dark: false,
        themes: {
            light: theme,
        },
    },
});
