<template>
    <task-item-link
        :value="item"
        :text-only="options.textOnly"
        v-bind="$attrs"
        v-on="$listeners" />
</template>

<script>
export default {
    types: [
        "taskItem"
    ],
    components: {
        taskItemLink: () => import("@/features/tasks/views/TaskItemLink")
    },
    props: {
        value: {
            type: Number,
            default: () => null,
        },
        options: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        item() {
            let lookup = this.options?.lookup;
            if(!lookup || !this.value) {
                return null;
            }
            return lookup[this.value.toString()]
        }
    }
};
</script>
