import api from '@/services/apiService'
import { buildFilter } from './filterBuilder'
import { equal, or } from '@/services/filtering'
import { list } from "@/features/schemas/services/schemaApi";

export async function tasks(tasksFilter, ids = null) {
    let filter = buildFilter(tasksFilter, ids)
    let tasks = await api.list("taskItems", { filter, sortBy: "sortOrder" })
    return tasks
}

export async function comments(taskItemId) {
    let filter = equal("taskItemId", taskItemId);
    let comments = await api.list("taskComments",
        { filter, sortBy: "createdAt", direction: "descending" });
    return comments;
}

export async function taskActivities(taskItemId) {
    let filter = equal("taskItemId", taskItemId);
    let taskActivities = await api.list("taskActivities",
        { filter, sortBy: "createdAt", direction: "descending" });
    return taskActivities;
}

export async function getFiles(id) {
    let query = {
        filter: equal("taskItemId", id)
    }
    let taskFiles = await list("taskFile", query);
    if (taskFiles.items.length <= 0) {
        return;
    }

    let fileQuery = {
        filter: or(taskFiles.items.map(e => equal("id", e.fileId)))
    };
    return (await list("file", fileQuery)).items;
}

export default { tasks, comments, taskActivities, getFiles }
