<template>
    <v-container>
        <v-row>
            <app-thumbnail
                v-for="file in localFiles"
                :key="file.id"
                class="mr-2 mb-2"
                :file="file"
                @delete="deleteFile" />
            <app-file-drop @input="onAdd" />
        </v-row>
    </v-container>
</template>

<script>
import api from "@/features/schemas/services/schemaApi"

export default {
    props: {
        files: {
            type: Array,
            default: () => []
        },
        onDelete: {
            type: Function,
            default: null
        }
    },

    data() {
        return {
            localFiles: Array
        }
    },

    watch: {
        files: {
            immediate: true,
            handler(values) {
                this.localFiles = values
            }
        }
    },

    methods: {
        async deleteFile(id) {
            await this.onDelete(id);
            await api.delete("file", id);
            this.localFiles = this.localFiles.filter(file => file.id != id);

            // Parent require to have a method to delete file relationship item.
            // Or pass the entity name, Id to this component
            // for example: site-file
            this.$emit('delete', id);
        },

        async onAdd(file) {
            this.localFiles.push(file);
            // Parent require to have a method to add file relationship item.
            // Or pass the entity name, Id to this component
            // for example: site-file
            this.$emit('input', file.id);
        }
    }
}
</script>
