<!--
    This is a work in progress, split from the AppSearchSelect component. Move common functionality
    into a mixin.
-->
<template>
    <div class="app-search">
        <v-text-field
            v-if="!isEditing"
            key="displayField"
            class="app-search-item-display"
            :value="display"
            :label="label"
            :outlined="outlined"
            :dense="dense"
            :hide-details="hideDetails"
            autocomplete="off"
            @focus="edit" />

        <v-text-field
            v-if="isEditing"
            key="editField"
            v-model="searchText"
            autocomplete="off"
            autofocus
            :label="label"
            :outlined="outlined"
            :dense="dense"
            :hide-details="hideDetails"
            @blur="clear"
            @keydown="onKeyDown" />

        <v-simple-table v-if="items.length" class="app-search-results" dense>
            <thead>
                <tr>
                    <th v-for="header in headers" :key="header.value">
                        {{ header.text }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(item, index) in items"
                    :key="item.id"
                    :class="itemClass(index)"
                    @mousedown="onItemClick(item)">
                    <td v-for="header in headers" :key="header.value">
                        <component
                            :is="getCellComponent(header.value)"
                            :key="header.value"
                            :value="item[header.value]"
                            :options="getCellOptions(header.value)" />
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </div>
</template>

<script>
import { isNullOrWhiteSpace, trim } from '@/services/stringUtility';
import { getLabel } from "@/features/schemas/services/labeller";
import { search } from '@/features/schemas/services/searcher';
import { getCellComponent, buildCellOptions } from "@/features/schemas/services/tableService";

export default {
    props: {
        entityKey: {
            type: String,
            default: null,
            required: true
        },
        value: {
            type: Object,
            default: null
        },
        label: {
            type: String,
            default: () => "Search"
        },
        fieldKeys: {
            type: Array,
            default: null
        },
        hideDetails: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: true,
        },
        outlined: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            isEditing: false,
            searchText: null,
            results: null,
            selectedIndex: -1,
            selectedItem: null
        }
    },
    computed: {
        items() {
            return this.results?.items ?? [];
        },
        headers() {
            let headers = this.results?.headers;
            if(headers == null) {
                return [];
            }

            if(this.fieldKeys == null) {
                return headers;
            }

            return headers.filter(h => this.fieldKeys.includes(h.value));
        },
        display() {
            if(this.selectedItem == null) {
                return "";
            }

            return getLabel(this.entityKey, this.selectedItem);
        }
    },
    watch: {
        // Can debounce like this if you like.
        // searchText: debounce(function() { return this.search(); })
        async searchText(){ await this.search(); }
    },
    methods: {
        edit() {
            this.isEditing = true;
            this.search();
        },
        async search() {
            if(isNullOrWhiteSpace(trim(this.searchText, "\""))) {
                this.results = null;
                return;
            }

            let searchText = this.searchText;
            let results = await search(this.entityKey, searchText, { includeLookups: true });

            // Avoid showing previous search if results arrive out of order.
            if(searchText === this.searchText) {
                this.results = results;
            }
        },
        onKeyDown(e) {
            let handledKeys = ["ArrowDown", "ArrowUp", "Enter" ];

            if(handledKeys.includes(e.key)) {
                let handler = this[`on${e.key}`];
                handler();
                e.preventDefault();
            }
        },
        onArrowDown() {
            let index = this.selectedIndex + 1;
            if(index < this.items.length) {
                this.selectedIndex = index;
            }
        },
        onArrowUp() {
            let index = this.selectedIndex - 1;
            if (index > -1) {
                this.selectedIndex = index;
            }
        },
        onEnter() {
            if(this.selectedIndex > -1) {
                this.selectedItem = this.items[this.selectedIndex];
                this.clear();
            }
        },
        onItemClick(item) {
            this.selectedItem = item;
            this.clear();
        },
        getCellComponent(fieldKey) {
            return getCellComponent(this.entityKey, fieldKey);
        },
        getCellOptions(fieldKey) {
            return buildCellOptions(this.results, fieldKey);
        },
        clear() {
            this.results = null;
            this.selectedIndex = -1;
            this.isEditing = false;
        },
        itemClass(index) {
            if(index == this.selectedIndex) {
                return "app-search-selected"
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/theme.scss";

.app-search {
    position: relative;
}

.app-search-results {
    position: absolute;
    z-index: 500;
    border: 1px solid rgba(0, 0, 0, 0.38);
    top: 40px;
    white-space: nowrap;
}

.app-search-selected {
    // I don't like using !important, but the vuetify selector for the hover is too specific and
    // likely to change.
    color: $color-primary;
    background-color: rgba($color-primary, 0.16) !important;
}

.app-search-results tr {
    cursor: pointer;
}

</style>
