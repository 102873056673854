import { camelToKebabCase } from '@/services/stringUtility'

const component = () =>
    import(/* webpackChunkName: "schemaItemView" */ "@/features/schemas/views/SchemaItemView.vue");

const entities = [
    "client",
    "file",
    "person",
    "project",
    "role",
    "taskItem",
    "user"
];

export function hasItemPage(entityKey) {
    return entities.includes(entityKey);
}

export default entities.map(e => ({
    path: `/${camelToKebabCase(e)}/:itemId`,
    name: e,
    staticProps: { entityKey: e },
    component
}));
