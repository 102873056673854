let report = () =>
    import(/* webpackChunkName: "report" */ "@/features/reports/views/Report.vue");
export default [
    {
        path: "/time-report",
        name: "timeReport",
        staticProps: { entityKey: "timeReport" },
    },
    {
        path: "/progress-report",
        name: "progressReport",
        staticProps: { entityKey: "progressReport" },
    },
    {
        path: "/performance-report",
        name: "performanceReport",
        staticProps: { entityKey: "performanceReport" },
    },
    {
        path: "/bucket-report",
        name: "bucketReport",
        staticProps: { entityKey: "bucketReport" },
    },
    {
        path: "/profitability-report",
        name: "profitabilityReport",
        staticProps: { entityKey: "profitabilityReport" },
    },
    {
        path: "/current-work-report",
        name: "currentWorkReport",
        staticProps: { entityKey: "currentWorkReport" },
    },
    {
        path: "/project-progress-report",
        name: "projectProgressReport",
        staticProps: { entityKey: "projectProgressReport" },
    },
    {
        path: "/weekly-hours-report",
        name: "weeklyHoursReport",
        staticProps: { entityKey: "weeklyHoursReport" },
    },
    {
        path: "/schedule-report",
        name: "scheduleReport",
        staticProps: { entityKey: "scheduleReport" },
    },
    {
        path: "/expected-delivery-report",
        name: "expectedDeliveryReport",
        staticProps: { entityKey: "expectedDeliveryReport" },
    },
    {
        path: "/work-allocation-report",
        name: "workAllocationReport",
        staticProps: { entityKey: "workAllocationReport" },
    },
    {
        path: "/project-schedule-report",
        name: "projectScheduleReport",
        staticProps: { entityKey: "projectScheduleReport" },
    },
    {
        path: "/simple-progress-report",
        name: "simpleProgressReport",
        staticProps: { entityKey: "simpleProgressReport" },
    }
].map(e => {
    return { ...e, component: report };
});
