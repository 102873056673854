<template>
    <div class="d-flex flex-column">
        <div class="d-flex flex-wrap">
            <app-button
                v-for="(item, i) in items"
                :key="i"
                class="mr-4 mb-2"
                :outlined="item !== selectedItem"
                @click="selectedItem = item">
                {{ getTitle(item) }}
            </app-button>
        </div>

        <component
            :is="getTabComponent"
            v-if="selectedItem"
            :item="selectedItem"
            :entity-key="entityKey"
            :item-id="itemId" />
    </div>
</template>

<script>
import { getDependants } from "@/features/schemas/services/schemaProvider";
import schemaMixin from '@/features/schemas/mixins/schemaMixin'
import tabs from "@/features/schemas/tabs";

export default {
    mixins: [schemaMixin],
    props: {
        itemId: {
            type: [Number, String],
            default: null
        },
        tabs: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            selectedItem: null
        }
    },
    computed: {
        items() {
            const dependants = getDependants(this.entityKey)
                .filter(e => !e.entity.key.includes('Report'));
            if (!this.tabs || this.tabs.length === 0) {
                return dependants;
            }

            // Sort the dependants based on the order of the tabs prop
            return dependants.sort((a, b) => {
                const indexOfA = this.tabs.indexOf(a.entity.key);
                const indexOfB = this.tabs.indexOf(b.entity.key);

                if (indexOfA > indexOfB) return 1;
                if (indexOfA < indexOfB) return -1;
                return 0;
            });
        },
        getTabComponent() {
            return tabs.getComponent(this.selectedItem.entity);
        }
    },
    methods: {
        getTitle(item) {
            // If the foreign key title matches this entity's title, then the foreign key entity
            // title will suffice.
            if(item.field.title === this.entity.singleTitle) {
                return item.entity.pluralTitle;
            }
            // Otherwise, include the field title to distinguish the source of the records.
            return `${item.entity.pluralTitle} (${item.field.title})`;
        }
    }
}
</script>
