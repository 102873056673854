
import strings from '@/services/stringUtility'
import store from '@/store/store'

function localSearch(items, searchText) {
    if(strings.isNullOrWhiteSpace(searchText)){
        return items;
    }
    let tokens = searchText.split(" ");
    return items.filter(i => tokens.every(t => isMatch(i, t)));
}

function isMatch(item, token) {
    let projectName = store.getters["data/getProject"](item.projectId)?.name;
    let clientName = store.getters["data/getClientByProjectId"](item.projectId)?.name;

    return (
        contains(item.id.toString(), token) ||
        contains(item.name, token) ||
        contains(item.description, token) ||
        contains(projectName, token) ||
        contains(clientName, token));
}

function contains(text, token) {
    if(text == null) {
        return false;
    }

    return text
        .toLowerCase()
        .includes(token.toLowerCase()) ?? false;
}

export default localSearch;
