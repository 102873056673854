<template>
    <div class="d-flex flex-column schema-table">
        <div v-if="!tableOnly" class="d-flex align-center mb-5">
            <v-text-field
                v-model="searchText"
                class="no-print"
                label="Search"
                hide-details
                dense
                color="primary"
                outlined
                :dark="darkMode"
                prepend-inner-icon="mdi-magnify" />

            <slot name="add-button">
                <app-icon-button
                    v-if="canAdd"
                    class="ml-4 no-print"
                    color="primary"
                    icon="mdi-plus"
                    @click="add" />
            </slot>

            <schema-excel-export-button
                v-if="!disableExport"
                v-bind="$attrs"
                class="ml-4 no-print"
                :entity-key="entityKey" />
        </div>

        <v-card :class="cardClasses" :elevation="outlined ? 0 : 4" :dark="darkMode">
            <schema-data-table
                ref="schemaDataTable"
                class="flex-grow-1"
                v-bind="$attrs"
                :entity-key="entityKey"
                :search-text="searchText"
                :readonly="readonly"
                @edit="edit">
                <template #extra-actions="{ item }">
                    <slot name="extra-actions" :item="item" />
                </template>
            </schema-data-table>
        </v-card>

        <schema-form-dialog
            :entity-key="entityKey"
            :value="editedItem"
            :form="form"
            v-bind="$attrs"
            @input="onItemSaved" />
    </div>
</template>

<script>
import schemaMixin from "@/features/schemas/mixins/schemaMixin";

export default {
    mixins:[schemaMixin],
    props: {
        form: {
            type: Function,
            default: null
        },
        disableExport: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        tableOnly: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            editedItem: null,
            searchText: "",
        };
    },

    computed: {
        cardClasses() {
            return {
                'flex-grow-1': true,
                'd-flex': true,
                'flex-column': true,
                'pa-2': true,
                'outlined-card': this.outlined
            }
        }
    },

    methods: {
        add() {
            this.editedItem = {};
        },
        edit(item) {
            this.editedItem = item;
        },
        async onItemSaved() {
            this.editedItem = null;
            if (this.$refs.schemaDataTable) {
                await this.$refs.schemaDataTable.refresh();
            }
        }
    }
};
</script>
