import accessMixin from '@/mixins/accessMixin';
import { canAdd, canEdit, canViewItem } from "@/features/schemas/services/schemaApi";
import { getEntity } from "@/features/schemas/services/schemaProvider";

export default {
    mixins: [accessMixin],
    props: {
        entityKey: {
            type: String,
            default: null
        }
    },
    computed: {
        entity() {
            return getEntity(this.entityKey);
        },
        canAdd() {
            const mixinValue = accessMixin.computed.canAdd.call(this);
            return mixinValue && canAdd(this.entityKey);
        },
        canEdit() {
            const mixinValue = accessMixin.computed.canEdit.call(this);
            return mixinValue && canEdit(this.entityKey);
        },
        canView() {
            return canViewItem(this.entityKey);
        }
    }
}
